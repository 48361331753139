import TagManager from 'react-gtm-module'
import {getItem} from '../router/products'
export const setViewProductsList = (products, searches, category) => {
    const item_list = JSON.stringify({searches, category})
    const items = products.map((product, index) => formatProduct(product, index))
    TagManager.dataLayer({
        dataLayer: {
          event: "view_item_list",
          ecommerce: {
            item_list_name: item_list,
            item_list_id: item_list,
            items: items
          }
        }
      });
}
const formatProduct = (product, index) => {
    return {
        item_id: product._id,
        item_name: product.name,
        item_category: product.category,
        price: product.price,
        item_brand: 'Pracownia Iskry',
        index: index,
        quantity: '1',
        currency: "PLN"
    }
}
export const setAddToCart = async (productID, number) => {
    const product = (await getItem(productID)).data
    const item = formatProduct(product, 0)
    item.quantity = number
    TagManager.dataLayer({
        dataLayer: {
          event: "add_to_cart",
          ecommerce: {
            currency: 'PLN',
            value: product.price * number,
            items: [item]
          }
        }
      });
}
export const setViewProduct = async (product) => {
    const item = formatProduct(product, 0)
    TagManager.dataLayer({
        dataLayer: {
          event: "view_item",
          ecommerce: {
            currency: 'PLN',
            value: product.price,
            items: [item]
          }
        }
      });
}
export const setRemoveFromCart = async (productID, number) => {
    const product = (await getItem(productID)).data
    const item = formatProduct(product, 0)
    item.quantity = number
    TagManager.dataLayer({
        dataLayer: {
          event: "remove_from_cart",
          ecommerce: {
            currency: 'PLN',
            value: product.price * number,
            items: [item]
          }
        }
      });
}
export const setViewCart = async (products) => {
    const items = products.map((product, index) => {
        const item = formatProduct(product, index)
        item.quantity = product.number
        return item
    })
    TagManager.dataLayer({
        dataLayer: {
          event: "view_cart",
          ecommerce: {
            currency: 'PLN',
            value: products.reduce((acc, elem) => acc + elem.price * elem.number, 0),
            items: items
          }
        }
      });
} 
export const setBeginPurchase = async (products) => {
    const items = products.map((product, index) => {
        const item = formatProduct(product, index)
        item.quantity = product.number
        return item
    })
    TagManager.dataLayer({
        dataLayer: {
          event: "begin_checkout",
          ecommerce: {
            currency: 'PLN',
            value: products.reduce((acc, elem) => acc + elem.price * elem.number, 0),
            items: items
          }
        }
      });
}
export const setAddShippingInfo = async (productsIDs, shipType) => {
    return new Promise(async (resolve, reject) => {
        const items = await Promise.all(productsIDs.map(async (product, index) => {
            const item = formatProduct((await getItem(product.id)).data, index)
            return { ...item, number: product.number }
        }))
        TagManager.dataLayer({
            dataLayer: {
              event: "add_shipping_info",
              ecommerce: {
                currency: 'PLN',
                value: items.reduce((acc, elem) => acc + elem.price * elem.number, 0) + shipType.price,
                items: items,
                shipping_tier: shipType.name
              }
            }
          });
        resolve(true)
    })
}
export const setPurchase = async (products) => {
  const shipMethod = products.pop()
  const items = await Promise.all(products.map(async (product, index) => {
    const item = formatProduct((await getItem(product._id)).data, index)
    return { ...item, number: product.quantity, quantity: product.quantity }
  }))
  TagManager.dataLayer({
      dataLayer: {
        event: "purchase",
        ecommerce: {
          currency: 'PLN',
          value: items.reduce((acc, elem) => acc + elem.price * elem.number, 0) + shipMethod.price,
          items: items,
          shipping_tier: shipMethod.name
        }
      }
    });
}