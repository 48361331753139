
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Products from "./pages/Products";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ProductDetails from "./pages/ProductDetails"
import ProductsPatch from './admin/ProductsPatch';
import ShippingDetails from "./pages/ShippingDetails"
import Account from "./pages/Account"
import Contact from "./pages/Contact"
import Returns from "./pages/Returns"
import ResetPassword from "./pages/ResetPassword"
import SetNewPassword from "./pages/SetNewPassword"
import ConfirmAccount from "./pages/ConfirmAccount"
import Shippings from "./pages/Shippings"
import AboutUs from "./pages/AboutUs"
import CookiesAllow from "./components/CookiesAllow"
import ProductsAdmin from "./admin/Products"
import OrderPanel from "./admin/OrderPanel"
import { useState } from 'react';
const App = () => {
  const [cookiesAllowed, setCookiesAllowed] = useState(localStorage.getItem("allowCookies"))
  return (
    <>
      {cookiesAllowed?null:<CookiesAllow setCookiesAllowed={setCookiesAllowed}/>}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Products />} />
          <Route path="/message/:message" element={<Products />} />
          <Route path="/message/:message/:orderID" element={<Products />} />
          <Route path="/shop" element={<Products />} />
          <Route path="/shop/:details" element={<Products />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/setNewPassword/:email/:token" element={<SetNewPassword />} />
          <Route path="/confirmAccount/:email/:token" element={<ConfirmAccount />} />
          <Route path="/products/:productID" element={<ProductDetails />} />
          <Route path="/shipping/:products" element={<ShippingDetails />} />
          <Route path="/account" element={<Account />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/returns" element={<Returns />} />
          <Route path="/shippings" element={<Shippings />} />
          <Route path="/aboutus" element={<AboutUs />} />

          <Route path="/qwerAdmin/products" element={<ProductsAdmin />} />
          <Route path="/qwerAdmin/login" element={<Login />} />
          <Route path="/qwerAdmin/products/:id" element={<ProductsPatch />} />
          <Route path="/qwerAdmin/orders/" element={<OrderPanel />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;