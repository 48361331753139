import React from 'react'
import Menu from './Menu';
import Categories from './Categories';
import { useState } from 'react';
import {Container, Logo, CategoriesShortcut, CategoriesBG,ShortWrapper, LinkText } from "../styles/navbar.jsx"
import { Link } from 'react-router-dom';
import { MobileMenuWrapper, RegularMenuWrapper } from '../styles/menu.jsx';
import MobileNavbar from "./MobileNavbar.jsx"
import backendAddress from "../scripts/backendAddress";
const Navbar = (props) => {
    const {cartView, setCartView, addToCartTrigger} = props
    const logoSrc = backendAddress + "/app//src//static//logo.png"
    const [mouseOnMenu, setMouseOnMenu] = useState(false)
    const [categoriesFocus, setCategoriesFocus] = useState(false)
    const [accountFocus, setAccountFocus] = useState(false)
    const [mouseOnAccountMenu, setMouseOnAccountMenu] = useState(false)
    const [user, setUser] = useState(null)
    return (
        <div>
            <Container>
                <Link to="/">
                    <Logo src={logoSrc} />
                </Link>
                <RegularMenuWrapper>
                    <Menu 
                    user={user}
                    setUser = {setUser}
                    addToCartTrigger={addToCartTrigger} 
                    mouseOnAccountMenu={mouseOnAccountMenu}
                    setMouseOnAccountMenu={setMouseOnAccountMenu}
                    accountFocus={accountFocus}
                    setAccountFocus={setAccountFocus}
                    cartView={cartView}
                    setCartView={setCartView}
                    />
                        <ShortWrapper>
                        <CategoriesShortcut 
                        onFocus={()=>{setCategoriesFocus(true)}}
                        onBlur={()=>{if(!mouseOnMenu)setCategoriesFocus(false)}}
                        >Kategorie</CategoriesShortcut>
                        
                        <LinkText to={"/aboutus"}>O nas</LinkText>
                        <LinkText>Blog</LinkText>
                        {categoriesFocus ? 
                        <CategoriesBG
                            onMouseEnter={()=>setMouseOnMenu(true)}
                            onMouseLeave={()=>setMouseOnMenu(false)}
                            onMouseUp={()=>{setTimeout(()=>{setCategoriesFocus(false);setMouseOnMenu(false)})}}
                        ><Categories /></CategoriesBG> 
                        : null}</ShortWrapper>
                </RegularMenuWrapper>
                    
            </Container>
            <MobileNavbar
                        cartView = {cartView} 
                        setCartView = {setCartView}
                        user = {user}
                        setUser={setUser}
                        addToCartTrigger={addToCartTrigger} 
                    />
        </div>
    )
}

export default Navbar