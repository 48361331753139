
import Cart from '../pages/Cart';
import { useState, useEffect } from 'react'
import { getCart } from '../controller/cart';
import backendAddress from "../scripts/backendAddress";
import { ShortcutIcon, ShortcutHolder, Notification, CartHolder, CartBackground } from "../styles/menu.jsx"
import { setViewCart } from '../controller/dataLayerSetter.js';
const CartShortcut = (props) => {
    const [cartFocus, setCartFocus] = useState(false)
    const { addToCartTrigger, cartView, setCartView, setUser, logOut, } = props
    const [cart, setCart] = useState([])
    const [changNumberTrigger, setChangeNumberTrigger] = useState(true)
    let totalNumberOfItems = cart.length > 0 ? cart.map(item => item.name ? item.number: 0).reduce((total, number) => total + number) : 0
    const changeCart = async () => {
        let {cart, user} = await getCart()
        setCart(cart)
        setUser(user)
    }
    useEffect(() => {
        changeCart()
    }, [addToCartTrigger, changNumberTrigger, logOut])
    const cartImg = backendAddress + "/app//src//static//cartIcon.png"
    
    const [mouseOnCart, setMouseOnCart] = useState(false)
    return (
        <ShortcutHolder
            focus = {cartFocus}
            onClick={()=>{if(!cartView){
                setViewCart(cart)
                setCartFocus(true)
            }}}
            onBlur={()=>{if(!mouseOnCart)setCartFocus(false)}}
        >
            {
                    cartView ?
                <CartBackground onClick={()=>{if(!mouseOnCart)setCartView(false)}}>
                    <CartHolder>
                        <Cart
                        setMouseOnCart = {setMouseOnCart}
                        setCartFocus = {setCartFocus}
                        changNumberTrigger={changNumberTrigger}
                        setChangeNumberTrigger={setChangeNumberTrigger}
                        cart={cart}
                        isEmpty={(totalNumberOfItems <= 0)}
                        />
                    </CartHolder>
                </CartBackground>
                :null
            }
            {totalNumberOfItems > 0 ? <Notification>{totalNumberOfItems}</Notification> : null}
            <ShortcutIcon src={cartImg}/>
            {cartFocus ?
                <Cart
                    cartFocus = {cartFocus}
                    setMouseOnCart = {setMouseOnCart}
                    setCartFocus = {setCartFocus}
                    changNumberTrigger={changNumberTrigger}
                    setChangeNumberTrigger={setChangeNumberTrigger}
                    cart={cart}
                    isEmpty={(totalNumberOfItems <= 0)}
                ></Cart> : null
            }

        </ShortcutHolder>
    )
}
export default CartShortcut