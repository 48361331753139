import Navbar from '../components/Navbar'
import { BackGround, Title, Card, Text, PostImg, DoubleColumnHolder, TextInside, DoubleColumnHolder2 } from "../styles/account.jsx"
import Footer from '../components/Footer'
import { ButtonHolder  } from "../styles/signUp.jsx"
import ToShopButton from '../components/ToShopButton'
import backendAddress from '../scripts/backendAddress.js'
const Returns = () => {
    return(
        <>
            <BackGround>
                <Navbar />
                <ButtonHolder>
                    <ToShopButton />
                </ButtonHolder>
                <Card>
                    <Title>O nas</Title><br /><br/><br/>
                    <Text>
                    Nasza historia to opowieść o pasji, miłości do natury i potrzebie tworzenia piękna własnymi rękami. To historia o tym, jak marzenie kiełkuje, żeby w końcu zamienić się w rzeczywistość. <br/>
                    <br/><br/>
                    Jest nas dwoje – Justyna i Piotr. Iskry dwie. Mentalnie i z nazwiska. Stąd nazwa naszej firmy. <br/>
                    <br/><br/>
                    <DoubleColumnHolder>
                    <PostImg src={backendAddress + "/app//src//static//portret_justyna_2.jpeg"}/>
                    <TextInside>
                    &nbsp;&nbsp;<b>Justyna</b> – serce i dusza naszej marki. Od dzieciństwa jej dłonie zawsze były zajęte rysowaniem, dzierganiem, wycinaniem, dłubaniem w ziemi. Teraz, zamiast szydełka i nożyczek  najczęściej trzyma w rękach roślinne oleje, napary z ziół, hydrolaty i olejki eteryczne. Każdy kosmetyk, który tworzy, powstaje z uważnością, by nie tylko pielęgnował skórę, ale i koił zmysły, otulał zapachem, przynosił radość.  <br/>
                    <br/><br/>
                    Przygoda z kosmetykami zaczęła się niewinnnie od zrobienia mydła. Proces ten tak bardzo ją zafascynował, że w ciągu kilku lat zrobiła ich setki, testując i porównując ich właściwości. Po mydle przyszedł czas na masła i kremy. Szalone Festiwale Mydła i Dzikiej Kosmetyki w Muchowie i Borowicach, kursy i szkolenia z dziedziny kosmetyki naturalnej były następnym krokiem w doskonaleniu wiedzy i umiejętności, które stały się pasją i nowym stylem życia.<br/>  
                    <br/><br/>
                    Uwielbia lato, kobiece spotkania, fotografię i przedmioty z duszą.  <br/>
                    <br/><br/>
                    </TextInside>
                    </DoubleColumnHolder>
                    <DoubleColumnHolder2>
                    <TextInside>
                    &nbsp;&nbsp;<b>Piotr</b> – dusza księgowego, mocno stąpający po ziemi strażnik terminów i porządku. Jak nikt potrafi wyliczyć koszty i chłodno popatrzeć na problem, kiedy emocje biorą górę. Kiedy przyszedł czas, zamienił tabelki Excela na kielnię i wiertarkę i własnymi rękami zbudował pracownię. Oddał na nią garaż, a potem nauczył się kłaść płytki, wstawiać okna i drzwi, malować ściany. Stworzył miejsce, w którym marzenia zamieniają się w rzeczywistość. <br/>
                    Po godzinach miłośnik wypraw górskich i kabaretu. <br/>
                    </TextInside>
                    
                    <PostImg src={backendAddress + "/app//src//static//portret_piotr.jpeg"}/>
                    </DoubleColumnHolder2>
                    <br/><br/>
                    Nasze kosmetyki powstają z miłości do natury. Każdy składnik wybieramy świadomie – sięgamy po najlepsze oleje, zioła i hydrolaty. Wybieramy szkło zamiast plastiku, prostotę zamiast nadmiaru. <br/>
                    <br/><br/>
                    Dbamy o Ciebie i o planetę. Ekologiczne opakowania, świadome wybory, minimalizm odpadów – to dla nas codzienność. Tworzymy odpowiedzialnie, bo wierzymy, że piękno nie może szkodzić naturze.<br/>
                    <br/><br/>
                    Kosmetyki z naszej pracowni to nie tylko pielęgnacja – to rytuał, moment zatrzymania się,  oddechu. Zapachy wpływają na emocje, dotyk sprawia, że czujemy się bardziej obecni. Praca w naszej pracowni jest jak medytacja – chwila tu i teraz, w której wlewamy spokój do słoiczków, by później trafił do Twoich rąk. <br/>
                    <br/><br/>
                    Jeśli tu jesteś, to znaczy, że czujesz tak jak my. <br/><br/>
                    Rozgość się, poczuj, spróbuj. <br/><br/>
                    To miejsce jest dla Ciebie.<br/><br/><br/>
                    </Text>
                </Card>
            </BackGround>
            <Footer/>
        </>
    )
}
export default Returns