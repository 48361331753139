import styled from "styled-components"
import { Link } from "react-router-dom"
export const OrderBackground = styled.div`
    background-color: rgb(247, 245, 243);
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 2px 2px 2px 1px rgba(49, 49, 49, 0.2);
`
export const PostImg = styled.img`
    width: 100%;
    height: min-content;
    object-fit: contain;
    margin-bottom: 30px;
    @media (min-width: 850px){
        margin: 30px;
        margin-left: 0;
    }

`
export const TextInside = styled.div`
    @media (min-width: 850px){
        margin: 30px;
    }
`
export const DoubleColumnHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: top;
    @media (max-width: 850px){
        flex-direction: column;
    }
    & > * {
        flex: 1;
    }
`
export const DoubleColumnHolder2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: top;
    @media (max-width: 850px){
        flex-direction: column-reverse;
    }
    & > * {
        flex: 1;
    }
`
export const Text = styled.p`
    color: rgb(150, 132, 107);
    margin: 10px;
    margin-left: 30px;
    font-size: 20px;
    width: 80vw;
    @media (max-width: 850px){
        width: calc(100vw - 60px);
    }
`
export const TextRight = styled.p`
    color: rgb(150, 132, 107);
    margin: 10px;
    margin-right: 30px;
    font-size: 20px;
    text-align: right;
`
export const BackGround = styled.div`
    background-color: white;
    padding-bottom: 20px;
    min-height: 100vh;
`
export const Title = styled.h2`
    color: rgb(150, 132, 107);
    margin-top: 20px;
    font-size: 30px;
    text-align: center;
    font-weight: 100;
`
export const Card = styled.div`
    background-color: rgb(247, 245, 243);
    padding-bottom: 10px;
    @media (min-width: 850px){
        min-width: 50vw;
        width: fit-content;
        margin: auto;
        margin-top: 60px;
        padding: 10px 30px;
        box-shadow: 2px 2px 2px 1px rgba(49, 49, 49, 0.2);
    }
    @media (max-width: 850px){
        margin-bottom: -20px;
        margin-top: -20px;
        
    }
`
export const Header = styled.p`
    font-size: 20px;
    color: rgb(81, 72, 59);
    font-weight: 100;
    min-width: 60vw;
    text-align: center;
`
export const Value = styled(Link)`
    font-size: 20px;
    color: black;
    text-decoration: none;
    margin-left: 20px;
    display: block;
    width: fit-content;
    margin: auto;
    text-align: center;
`