import axios from "axios"
import { getToken } from '../scripts/storage';
import { resetCart } from "../controller/cart";
import backendAddress from "../scripts/backendAddress";
import { setPurchase } from "../controller/dataLayerSetter";
export const order = async (values, actions) => {
    const options = {
        method: 'POST',
        url: backendAddress + "/orders",
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        },
        data: values,
    };

    try {
        let response = await axios.request(options)
        resetCart()
        window.location.replace(response.data.url)
        if (!response.error) actions.resetForm()
        return { log: "zamówienie pomyślnie sfinalizowane", error: false }
    } catch (error) {
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else {console.log(error.message);return { log: "wystąpił błąd", error: true }}
    }
}
export const orderForLogged = async (values, actions) => {
    const options = {
        method: 'POST',
        url: backendAddress + "/orders/logged",
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization': `Bearer ${getToken()}`
        },
        data: values,
    };

    try {
        let response = await axios.request(options)
        resetCart()
        window.location.replace(response.data.url)
        if (!response.error) actions.resetForm()
        return { log: "zamówienie pomyślnie sfinalizowane", error: false }
    } catch (error) {
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else if (error.response.status)
            throw error
        else {console.log(error.message);return { log: "wystąpił błąd", error: true }}
    }
}
export const getOrders = async () => {
    const options = {
        method: 'GET',
        url: backendAddress + "/orders",
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization': `Bearer ${getToken()}`
        }
    };

    try {
        return await axios.request(options)
    } catch (error) {
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else return { log: "wystąpił błąd", error: true }
    }
}
export const patchStatus = async (orderId, status) => {
    const options = {
        method: 'PATCH',
        url: backendAddress + `/orders/${orderId}/${status}`,
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization': `Bearer ${getToken()}`
        }
    };

    try {
        return await axios.request(options)
    } catch (error) {
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else return { log: "wystąpił błąd", error: true }
    }
}
export const shipMethods = async () => {
    const options = {
        method: 'GET',
        url: backendAddress + "/orders/shipMethods",
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        },
    };

    try {
        let response = await axios.request(options)
        return { data: response, log: "zamówienie pomyślnie sfinalizowane", error: false }
    } catch (error) {
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else return { log: "wystąpił błąd", error: true }
    }
}
export const getPurchaseStatus = async (odrderID) => {
    const options = {
        method: 'GET',
        url: backendAddress + `/orders/purchase/${odrderID}`,
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        },
    };

    try {
        let response = (await axios.request(options)).data
        if (response.flag) setPurchase(response.items)
    } catch (error) {
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else return { log: "wystąpił błąd", error: true }
    }
}

export const getMyOrders = async (callback) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'GET',
            url: backendAddress + "/orders/myData",
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
        };

        try {
            let response = await axios.request(options)
            resolve(response.data);
        } catch (error) {
            if(callback)callback()
            else reject(error)
        }
    })
}