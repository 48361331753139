import { addToCart as addToCartReq, patchNumberOfProduct as patchNumberOfProductReq, resetCart as resetCartReq } from "../router/cart"
import { getItem } from "../router/products"
import { getUser } from "../router/user"
import { saveToken } from "../scripts/storage"
import { addToCookiesCart, getCookiesCart, patchNumberOfProductCookies, resetCart as resetCartCookie } from "./cartCookies"
import {setAddToCart, setRemoveFromCart} from "./dataLayerSetter"
export const addToCart = async (productID, number = 1) => {
    return new Promise(async (resolve, reject) => {
        setAddToCart(productID, number)
        try {
            resolve(await addToCartReq(productID, number))
        } catch (e) {
            resolve(addToCookiesCart(productID, number))
        }
    })
}
export const resetCart = async (productID, number = 1) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await resetCartReq())
        } catch (e) {
            resolve(resetCartCookie())
        }
    })
}
export const getCart = async () => {
    return new Promise(async (resolve, reject) => {
        let minimizedCart
        let user
        try {
            user = await getUser()
            minimizedCart = user.cart
        } catch (e) {
            saveToken("") 
            console.log(e.message)
            minimizedCart = getCookiesCart()
        }
        let cart = await Promise.all(minimizedCart.map(async elem => {
            return new Promise(async (resolve, reject) => {
                let product = await getItem(elem.product)
                resolve({ ...product.data, number: elem.number, amount: product.data.number })
            })
        }))
        resolve({cart, user})
    })
}
export const patchNumberOfProduct = async (id, change, number, changNumberTrigger, setChangeNumberTrigger) => {
    if (change == -1)
        setRemoveFromCart(id, 1)
    else
        setAddToCart(id, 1)
    return new Promise(async (resolve, reject) => {
        let res
        try {
            res = await patchNumberOfProductReq(id, number)
        } catch (e) {
            res = patchNumberOfProductCookies(id, number)
        }
        setChangeNumberTrigger(!changNumberTrigger)
        resolve(res)
    })
}